import React from "react";
import styles from "./test.module.css";

const AboutUs = () => {
  return (
    <div className={styles.container}>
      {/* Header Section */}
      <header className={styles.header}>
        <div className={styles.headerTop}>
          <h1>About Us</h1>
          <a href="/login" className={styles.loginButton}>
            Login
          </a>
        </div>
      </header>

      {/* Data Section */}
      <section className={styles.dataSection}>
        <h2>DATA</h2>
        <p>
          Data refers to a collection of facts, figures, and statistics used for
          analysis, research, and decision-making. In the modern context, data
          has become a critical asset in various domains such as business,
          science, technology, and social sciences. It is the foundation of
          machine learning, artificial intelligence, and analytics.
        </p>
        <a href="/explore" className={styles.exploreLink}>
          Explore more
        </a>
      </section>

      {/* Passion Dimensions Section */}
      <section className={styles.passionDimensions}>
        <h2>PASSION DIMENSIONS</h2>
        <div className={styles.dimension}>
          <h3>Probing</h3>
          <p>
            Data is the starting point for inquiry and exploration. Researchers
            and analysts use data to investigate new phenomena, probe the
            unknown, and form hypotheses. In the context of artificial
            intelligence, data is continuously analyzed to find hidden patterns,
            trends, and insights.
          </p>
        </div>
        <div className={styles.dimension}>
          <h3>Innovating</h3>
          <p>
            Innovation thrives on data. It powers innovation in technology (such
            as AI, big data, blockchain), healthcare (precision medicine), and
            various industries (like fintech or agriculture). Data facilitates
            the creation of smarter systems, from predictive analytics to
            automated workflows.
          </p>
        </div>
        {/* Add other sections like "Acting", "Scoping", etc., similarly */}
      </section>

      {/* Footer Section */}
      <footer className={styles.footer}>
        <p>
          Office #5, Block 1, Lloyds Chambers, Mangalwar Peth, Near Ambedkar
          Bavan, Pune - 4110111
        </p>
        <p>+91 9123456780</p>
        <div className={styles.footerLinks}>
          <a href="/terms">Terms and Conditions</a> |{" "}
          <a href="/privacy">Privacy Policy</a>
        </div>
      </footer>
    </div>
  );
};

export default AboutUs;
