import React, { useState } from "react";

import * as XLSX from "xlsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as API from "../Endpoint/Endpoint";

const ExcelLoad = () => {
  const [excelData, setExcelData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Assuming the first row contains headers
      const headers = jsonData[0];
      const rows = jsonData.slice(1).map((row) =>
        headers.reduce((acc, header, index) => {
          acc[header] = row[index] || ""; // Map headers to row data
          return acc;
        }, {})
      );

      setExcelData(rows);
    };
    reader.readAsArrayBuffer(file);
  };

  const formatData = (data) => {
    const user_id = sessionStorage.getItem("user_id") || "default_id"; // Replace with actual user ID
    return data.map((row) => ({
      user_id,
      title: `<p>${row["Title"] || ""}</p>`,
      description: `<p>${row["Description"] || ""}</p>`,
      category: `<p>${row["Category"] || ""}</p>`,
      referenceLink: `<p>${row["Reference Link"] || ""}</p>`,
      videoRecommendations: `<p>${row["Video Recommendations"] || ""}</p>`,
      podcastRecommendations: `<p>${row["Podcast Recommendations"] || ""}</p>`,
      undiscoveredInnovation: `<p>${
        row["Undiscovered Possible Innovation"] || ""
      }</p>`,
      researchOpportunities: `<p>${row["Research Opportunities"] || ""}</p>`,
      patents: `<p>${row["Patents (if any)"] || ""}</p>`,
      lessonsToLearn: `<p>${row["Lessons to Learn"] || ""}</p>`,
      startups: `<p>${row["Startups in this Space"] || ""}</p>`,
      advertisementGallery: `<p>${row["Advertisement Gallery"] || ""}</p>`,
      sections: [
        {
          name: "PASSION Dimensions",
          dimensions: [
            { dimension: "Probing", content: row["Probing"] || "" },
            { dimension: "Innovating", content: row["Innovating"] || "" },
            { dimension: "Acting", content: row["Acting"] || "" },
            { dimension: "Scoping", content: row["Scoping"] || "" },
            { dimension: "Setting", content: row["Setting"] || "" },
            { dimension: "Owning", content: row["Owning"] || "" },
            { dimension: "Nurturing", content: row["Nurturing"] || "" },
          ],
        },
        {
          name: "PRUTL Dimensions",
          subsections: [
            {
              category: "Positive Soul",
              subdimensions: [
                { subdimension: "Peace", content: row["Peace"] || "" },
                { subdimension: "Respect", content: row["Respect"] || "" },
                { subdimension: "Unity", content: row["Unity"] || "" },
                { subdimension: "Trust", content: row["Trust"] || "" },
                { subdimension: "Love", content: row["Love"] || "" },
              ],
            },
            {
              category: "Negative Soul",
              subdimensions: [
                { subdimension: "Pride", content: row["Pride"] || "" },
                { subdimension: "Rule", content: row["Rule"] || "" },
                { subdimension: "Usurp", content: row["Usurp"] || "" },
                { subdimension: "Tempt", content: row["Tempt"] || "" },
                { subdimension: "Lust", content: row["Lust"] || "" },
              ],
            },
            {
              category: "Positive Materialism",
              subdimensions: [
                { subdimension: "Protector", content: row["Protector"] || "" },
                { subdimension: "Recycling", content: row["Recycling"] || "" },
                { subdimension: "Utility", content: row["Utility"] || "" },
                {
                  subdimension: "Tangibility",
                  content: row["Tangibility"] || "",
                },
                { subdimension: "Longevity", content: row["Longevity"] || "" },
              ],
            },
            {
              category: "Negative Materialism",
              subdimensions: [
                {
                  subdimension: "Possession",
                  content: row["Possession"] || "",
                },
                { subdimension: "Rot", content: row["Rot"] || "" },
                {
                  subdimension: "Negative-Utility",
                  content: row["Negative-Utility"] || "",
                },
                { subdimension: "Trade", content: row["Trade"] || "" },
                { subdimension: "Lessen", content: row["Lessen"] || "" },
              ],
            },
          ],
        },
      ],
    }));
  };

  const handleSubmit = async () => {
    if (!excelData.length) {
      toast.error("No data to submit.");
      return;
    }
    setLoading(true);

    const formattedData = formatData(excelData);

    try {
      const response = await axios.post(
        `${API.CLIENT_URL}/solr/research/update/json/docs`,
        formattedData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      console.log("Data submitted successfully:", response.data);

      // Commit the changes in Solr
      await axios.get(`${API.CLIENT_URL}/solr/research/update?commit=true`);
      toast.success("Data submitted and committed successfully.");
      navigate("/user");
    } catch (error) {
      toast.error("Error submitting data.");
      console.error("Error submitting data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container">
        <h2>Upload Excel File</h2>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
        <button onClick={handleSubmit} disabled={loading || !excelData.length}>
          {loading ? "Submitting..." : "Submit to Solr"}
        </button>
        {excelData.length > 0 && (
          <div>
            <h3>Preview Data</h3>
            <table border="1">
              <thead>
                <tr>
                  {Object.keys(excelData[0]).map((key, index) => (
                    <th key={index}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {excelData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(row).map((value, colIndex) => (
                      <td key={colIndex}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExcelLoad;
